import useNotifications from "./useNotifications";
import { strings } from "../utils/i18n.utils";
import { useCallback, useRef, useState } from "react";
import useAuth from "./useAuth";
import Cookies from "js-cookie";
import { getBaseUrl } from "../utils/url.utils";

const useAPI = (props) => {
    const { addNotification, removeNotification } = useNotifications();
    const { cookie } = useAuth();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(props && "loading" in props ? props.loading : false);
    const [error, setError] = useState(null);
    const [callbackData, setCallbackData] = useState(null);
    const [streamedData, setStreamedData] = useState([]);
    const cancelToken = useRef(null); // Add cancel token reference

    const ignoredStatusCodes = props?.ignoredStatusCodes || [];

    const defaultErrorHandler = (error) => {
        if (error.name === "AbortError") {
            console.log("API call aborted");
            return;
        } else if (
            (!props || props?.showError === undefined || props.showError) &&
            error &&
            !ignoredStatusCodes.includes(error?.code)
        ) {
            addNotification("api", strings.error_api, error.message);
        }
    };

    const callAPI = useCallback(
        (method, path, entity, callbackData, errorHandler = defaultErrorHandler, retries = 3) => {
            const promise = new Promise(async (resolve, reject) => {
                cancelToken.current = new AbortController(); // Create a new cancel token
                const { signal } = cancelToken.current;

                for (let i = 0; i < retries + 1; i++) {
                    let shouldRetry = i < retries;

                    if (i > 0) {
                        // Wait before retrying
                        await new Promise((resolve) => setTimeout(resolve, 1000 * i));
                    }

                    try {
                        setError(null);
                        setLoading(true);
                        setCallbackData(callbackData);
                        console.log("Calling " + method + " " + path);
                        removeNotification("api");

                        const naturalCookie = Cookies.get("jwt_token");
                        let headers = {
                            "X-Authorization": `Bearer ${naturalCookie || cookie}`,
                            ...(entity
                                ? {
                                      "Content-Type": "application/json",
                                  }
                                : {}),
                        };

                        const response = await fetch(getBaseUrl() + path, {
                            method: method,
                            body: entity ? JSON.stringify(entity) : null,
                            headers: headers,
                            credentials: "include",
                            signal, // Pass the cancel token signal to the fetch request
                        });

                        if (!response.ok) {
                            const json = await response.json();
                            let message = json.message ? json.message : response.status + " " + response.statusText;

                            let error = {
                                message: message,
                                code: json.code,
                                errorDetails: json,
                                status: response.status,
                                retrying: shouldRetry,
                                retry: i,
                            };

                            console.log("Error invoking API", error);
                            setError(error);
                            if (errorHandler) errorHandler(error);

                            shouldRetry = response.status > 500;

                            if (!shouldRetry) {
                                reject(error);
                                break;
                            }
                        } else {
                            const json = await response.json();

                            setResponse(json);
                            setError(null);
                            setLoading(false);

                            resolve(json);
                            return;
                        }
                    } catch (error) {
                        shouldRetry = false;
                        console.log("Error invoking API", error);
                        setError(error);

                        if (errorHandler) errorHandler(error);

                        if (!shouldRetry) {
                            reject(error);
                            break;
                        }
                    }
                }

                setLoading(false);
            });

            promise.catch(() => {
                setLoading(false);
            });
            return promise;
        },
        [cookie]
    );

    const streamAPI = useCallback(
        (method, path, entity, errorHandler = defaultErrorHandler) => {
            if (cancelToken.current) {
                cancelToken.current.abort();
            }
            cancelToken.current = new AbortController(); // Create a new cancel token
            const { signal } = cancelToken.current;

            const fetchStream = async () => {
                try {
                    setError(null);
                    setLoading(true);
                    setCallbackData(null);
                    setStreamedData([]); // Reset streamed data
                    console.log("Streaming " + method + " " + path);
                    removeNotification("api");

                    const naturalCookie = Cookies.get("jwt_token");
                    let headers = {
                        "X-Authorization": `Bearer ${naturalCookie || cookie}`,
                        ...(entity
                            ? {
                                  "Content-Type": "application/json",
                              }
                            : {}),
                    };

                    const response = await fetch(getBaseUrl() + path, {
                        method: method,
                        body: entity ? JSON.stringify(entity) : null,
                        headers: headers,
                        credentials: "include",
                        signal, // Pass the cancel token signal to the fetch request
                    });

                    if (!response.ok) {
                        const json = await response.json();
                        let message = json.message ? json.message : response.status + " " + response.statusText;

                        let error = {
                            message: message,
                            code: json.code,
                            errorDetails: json,
                            status: response.status,
                        };

                        console.log("Error invoking API", error);
                        setError(error);
                        if (errorHandler) errorHandler(error);

                        return;
                    }

                    const reader = response.body.getReader();
                    const decoder = new TextDecoder();
                    let buffer = "";

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        buffer += decoder.decode(value, { stream: true });
                        let lines = buffer.split("\n");

                        // Keep the last partial line in the buffer
                        buffer = lines.pop();

                        for (let line of lines) {
                            if (line.trim()) {
                                try {
                                    const json = JSON.parse(line);
                                    setStreamedData((prevData) => [...prevData, json]); // Update state with new data
                                } catch (e) {
                                    console.error("Error parsing JSONL line", e);
                                }
                            }
                        }
                    }

                    // Process any remaining data in the buffer
                    if (buffer.trim()) {
                        try {
                            const json = JSON.parse(buffer);
                            setStreamedData((prevData) => [...prevData, json]); // Update state with new data
                        } catch (e) {
                            console.error("Error parsing JSONL line", e);
                        }
                    }

                    setLoading(false);
                } catch (error) {
                    if (error.name === "AbortError") {
                        console.log("Request canceled", error.message);
                    } else {
                        console.log("Error invoking API", error);
                        setError(error);
                        if (errorHandler) errorHandler(error);
                    }
                    setLoading(false);
                } finally {
                    cancelToken.current = null;
                }
            };

            fetchStream();
        },
        [cookie]
    );

    const cancelAPI = useCallback(() => {
        if (cancelToken.current) {
            cancelToken.current.abort(); // Abort the API call using the cancel token
        }
    }, []);

    return [
        {
            response,
            loading,
            error,
            callbackData,
            streamedData,
            setResponse,
            setLoading,
            setError,
        },
        callAPI,
        streamAPI, // Include the streamAPI function in the returned array
        cancelAPI, // Include the cancelAPI function in the returned array
    ];
};

export default useAPI;
