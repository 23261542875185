import { useEffect, useState } from "react";
import Skill from "../components/Skill";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { strings } from "../utils/i18n.utils";
import InfiniteScroll from "react-infinite-scroll-component";

const SkillsScreen = () => {
    const { currentSpace } = useAuth();
    const [skillsAPI, callSkillsAPI] = useAPI();
    const [skills, setSkills] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20;

    function removeSkill(skill) {
        skillsAPI.setResponse(skillsAPI.response.filter((s) => s.id !== skill.id));
    }

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        setSkills([]);
        loadMore();
    }

    function loadMore() {
        callSkillsAPI("GET", `/api/spaces/${currentSpace.id}/skills?offset=${skills.length}&limit=${limit}`);
    }

    useEffect(() => {
        if (skillsAPI.response) {
            setSkills([...skills, ...skillsAPI.response]);
            setHasMore(skillsAPI.response.length === limit);
        }
    }, [skillsAPI.response]);

    return (
        <>
            <h1>{strings.skills}</h1>

            {!skillsAPI.loading && skills.length === 0 && <p>{strings.skills_empty_state}</p>}

            <InfiniteScroll
                dataLength={skills.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<ZataLoader />}
                refreshFunction={refresh}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                scrollableTarget="content">
                {skills.map((skill) => (
                    <Skill skill={skill} removeSkill={() => removeSkill(skill)} />
                ))}
            </InfiniteScroll>
        </>
    );
};

export default SkillsScreen;
