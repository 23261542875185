import { useEffect } from "react";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import Skill from "../components/Skill";
import { ArrowArcRight, Barbell, Check, Lightbulb, NotePencil, Plus } from "@phosphor-icons/react";
import { Button } from "semantic-ui-react";
import { strings } from "../utils/i18n.utils";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Row } from "../components/Flex";
import { HomeTopBarPortal } from "../components/HomeTopBar";
import { EmptyState } from "../components/EmptyState";
import { currentLocation } from "../utils/url.utils";

const PlanScreen = ({}) => {
    const [skillContextAPI, callPlanContextAPI] = useAPI();
    const { currentSpace } = useAuth();

    useEffect(() => {
        callPlanContextAPI("GET", `/api/spaces/${currentSpace.id}/skill_context`);
    }, [currentSpace]);

    if (skillContextAPI.loading || !skillContextAPI.response) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <HomeTopBarPortal />
            <Row align="right">
                <div></div>
                <Button
                    as={Link}
                    to={`/spaces/${currentSpace.id}/skills?back=${currentLocation()}`}
                    icon={<NotePencil />}
                    content={strings.edit}
                />
                <Button
                    as={Link}
                    to={`/spaces/${currentSpace.id}/topics/new?back=${currentLocation()}`}
                    icon={<Plus />}
                    content={strings.add}
                />
            </Row>

            <h1>Your Skills</h1>
            <p>
                The study context is used to determine what you focus on next. It's a subset of all the skills and
                vocabulary in your queue.
            </p>
            <h2>Key</h2>
            <p>
                <Barbell /> You are learning this word
                <br />
                <Check /> You've completed this skill + vocabulary combination
                <br />
                <ArrowArcRight /> You told Jetway you know this skill + vocabulary word combinartion already and not to
                study it
                <br />
                <Lightbulb /> Jetway inferred that you know this skill + vocabulary based on your level and other
                skills.
                <br />
            </p>
            <h2>Next skills to learn</h2>
            <p>Skills and vocabulary that you have not yet studied.</p>
            {skillContextAPI.response?.skills_new?.map((skill) => (
                <Skill skill={skill} />
            ))}
            {skillContextAPI.response?.skills_new.length === 0 && <EmptyState message={strings.none_found} />}

            <h2>Skills to reinforce</h2>
            <p>
                Skills and vocabulary that you have done some exercises for already or which recently received grammar
                corrections for.
            </p>
            {skillContextAPI.response?.skills_in_progress?.map((skill) => (
                <Skill skill={skill} />
            ))}
            {skillContextAPI.response?.skills_in_progress.length === 0 && <EmptyState message={strings.none_found} />}

            <h2>Completed Skills</h2>
            <p>Skills and vocabulary that you know very well or that you told Jetway you know.</p>
            {skillContextAPI.response?.skills_mastered?.map((skill) => (
                <Skill skill={skill} />
            ))}
            {skillContextAPI.response?.skills_mastered.length === 0 && <EmptyState message={strings.none_found} />}

            <h2>Inferred Skills</h2>
            <p>Skills and vocabulary that Jetway inferred you know.</p>
            {skillContextAPI.response?.skills_inferred?.map((skill) => (
                <Skill skill={skill} />
            ))}

            {skillContextAPI.response?.skills_inferred.length === 0 && <EmptyState message={strings.none_found} />}
        </div>
    );
};

export default PlanScreen;
