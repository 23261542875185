import { useEffect } from "react";
import { useLanguage } from "../hooks/useLanguage";
import useAPI from "../hooks/useAPI";
import InfiniteScroll from "react-infinite-scroll-component";
import { ZataLoader } from "./ZataLoader";
import { Activity, ActivitySegment } from "./ActivityDetails";
import useAuth from "../hooks/useAuth";
import { HomeTopBarPortal } from "./HomeTopBar";

export const Talk = () => {
    const { currentSpace } = useAuth();
    const [activitiesAPI, callActivitiesAPI] = useAPI();
    const limit = 20;

    useEffect(() => {
        refresh();
    }, [currentSpace]);

    const refresh = () => {
        callActivitiesAPI("GET", `/api/spaces/${currentSpace.id}/activities?limit=${limit}`);
    };

    const loadMore = () => {
        callActivitiesAPI(
            "GET",
            `/api/spaces/${currentSpace.id}/activities?offset=${activitiesAPI.response.length}&limit=${limit}`
        );
    };

    return (
        <>
            <HomeTopBarPortal />
            {activitiesAPI.response === null && <ZataLoader />}
            {activitiesAPI.response !== null && (
                <InfiniteScroll
                    dataLength={activitiesAPI.response?.length}
                    next={loadMore}
                    hasMore={
                        activitiesAPI.loading || (activitiesAPI.response && activitiesAPI.response.length === limit)
                    }
                    loader={<ZataLoader />}
                    endMessage={<p></p>}
                    refreshFunction={refresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                    releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                    scrollableTarget="content">
                    {activitiesAPI.response.map((activity) => (
                        <ActivitySegment key={activity.id} activity={activity} showStart={false} />
                    ))}
                </InfiniteScroll>
            )}
        </>
    );
};
