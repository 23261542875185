import { Article, Barbell, House, List, Microphone, Queue, User } from "@phosphor-icons/react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import { strings } from "../utils/i18n.utils";
import "./BottomBarMenu.css";

const BottomBarMenuTile = ({ title, icon, href, notification }) => {
    const history = useHistory();

    return (
        <div className="bottom-tile" onClick={() => history.push(href)}>
            <div className="bottom-bar-icon">
                {icon}
                {notification > 0 && <div className={"bottom-bar-icon-notification"}>{notification}</div>}
            </div>

            <div className="bottom-tile-content">
                <p>{title}</p>
            </div>
            <div className="bottom-spacer" />
        </div>
    );
};

const BottomBarMenu = () => {
    const { currentSpace, statistics } = useAuth();

    return createPortal(
        <div id="bottombar" className="bottom-menu">
            <BottomBarMenuTile title={strings.home} icon={<House />} href={`/spaces/${currentSpace.id}/home`} />
            <BottomBarMenuTile
                title={strings.review}
                icon={<Barbell />}
                href={`/spaces/${currentSpace.id}/chat?activityType=review`}
                notification={statistics?.skill_vocab_pairs_needing_review}
            />
            <BottomBarMenuTile title={strings.talk} icon={<Microphone />} href={`/spaces/${currentSpace.id}/talk`} />{" "}
            <BottomBarMenuTile title={strings.skills} icon={<List />} href={`/spaces/${currentSpace.id}/plan`} />
            <BottomBarMenuTile title={strings.you} icon={<User />} href={`/spaces/${currentSpace.id}/you`} />
        </div>,
        document.getElementById("content-wrapper")
    );
};

export default BottomBarMenu;
