import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Flashcard } from "../components/ActivityDetails";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useTitle from "../hooks/useTitle";
import { useQuery } from "../hooks/useQuery";
import useAuth from "../hooks/useAuth";

const SkillComponentReview = ({ flashcard, review }) => {
    return (
        <div>
            {review.skill_component.skill.name_native} &rarr; {review.skill_component.vocab_phrase.text} &rarr;{" "}
            {review.skill_component.type}
            <Flashcard flashcard={flashcard} phrase={flashcard.phrase} />
        </div>
    );
};

const Exercises = () => {
    const [exerciseAPI, callExerciseAPI] = useAPI();
    const { currentSpace } = useAuth();
    useTitle("Exercises needing review", "Exercises needing review");
    const [exercises, setExercises] = useState([]);
    const limit = 20;
    const query = useQuery();

    function loadMore() {
        console.log("Loading more at", exercises.length);
        const skillId = query.get("skillId");
        let url = `/api/spaces/${currentSpace.id}}/flashcards?limit=${limit}&offset=${exercises.length}`;
        if (skillId) {
            url += `&skill_id=${skillId}`;
        }
        callExerciseAPI("GET", url);
    }

    function refresh() {
        setExercises([]);
        callExerciseAPI("GET", `/api/spaces/${currentSpace.id}/flashcards?limit=${limit}&offset=0`);
    }

    useEffect(() => {
        if (exerciseAPI.response) {
            setExercises((exercises) => [...exercises, ...exerciseAPI.response]);
        }
    }, [exerciseAPI.response]);

    useEffect(() => {
        loadMore();
    }, []);

    return (
        <div>
            <InfiniteScroll
                dataLength={exercises.length}
                next={loadMore}
                hasMore={exerciseAPI.loading || (exerciseAPI.response && exerciseAPI.response.length === limit)}
                loader={<ZataLoader />}
                endMessage={<p>No more!</p>}
                refreshFunction={refresh}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                scrollableTarget="content">
                {exercises.map((entry) => (
                    <SkillComponentReview flashcard={entry.flashcard} review={entry.skill_component_review} />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default Exercises;
