import { HomeTopBarPortal } from "../components/HomeTopBar";
import Progress from "../components/Progress";
import { useLanguage } from "../hooks/useLanguage";
import useTitle from "../hooks/useTitle";
import { strings } from "../utils/i18n.utils";

const YouScreen = () => {
    return (
        <>
            <HomeTopBarPortal />
            <Progress />
        </>
    );
};

export default YouScreen;
