import React from "react";
import { toCoolEmojis } from "../utils/emoji.utils";
import "./EmojiIcon.css";

const EmojiIcon = ({ emoji, notification, backgroundColor, noBackground = false }) => {
    return (
        <>
            <div className={`emoji-icon ${backgroundColor}`}>
                {toCoolEmojis(emoji)}
                {notification > 0 && <div className={"emoji-icon-notification"}>{notification}</div>}
            </div>
        </>
    );
};

export default EmojiIcon;
