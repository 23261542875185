import { House } from "@phosphor-icons/react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Container, Form, Label, Loader } from "semantic-ui-react";
import useAPI from "../hooks/useAPI";
import { useAudio } from "../hooks/useAudio";
import useAuth from "../hooks/useAuth";
import { useQuery } from "../hooks/useQuery";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import useWalkthrough from "../hooks/useWalkthrough";
import { toCoolEmojis } from "../utils/emoji.utils";
import { strings } from "../utils/i18n.utils";
import { isMobile } from "../utils/platform.utils";
import { ActivityProgressBar } from "./ActivityProgressBar";
import AudioPhrase from "./AudioPhrase";
import {
    Chat,
    ChatIconLeft,
    ChatIconRight,
    ChatInput,
    ChatMessageLeft,
    ChatMessageRight,
    ChatRowLeft,
    ChatRowRight,
    ChatWaitingIndiciator,
} from "./Chat";
import { SkillComponentDetailsPopup } from "./SkillComponentDetails";
import { FlashcardStopPopup } from "./FlashcardStop";
import Phrase from "./Phrase";
import PromptButtonBar from "./PromptButtonBar";
import { PromptInputBar } from "./PromptInputBar";
import { TopBar } from "./TopBar";

const DEFAULT_PROMPT_CONTEXT = {
    missedWordIds: [],
    grammarCorrectedWordIds: [],
    unknownWordIds: [],
    ignoredGrammarCorrections: [],
};

export function Prompt({}) {
    let { currentSpace } = useAuth();
    const history = useHistory();
    useTitle(strings.chat_title);
    const location = useLocation();

    const [promptAPI, , streamPromptAPI] = useAPI();
    const [replyAPI, , streamReplyAPI] = useAPI();
    const [sendCommandAPI, , streamSendCommandAPI] = useAPI();
    const prevReplyStreamDataLength = useRef(0);
    const prevPromptStreamDataLength = useRef(0);
    const prevCommandStreamDataLength = useRef(0);

    const [_, callAcceptGrammrCorrectionAPI] = useAPI();

    const [disabled, setDisabled] = useState(true);
    const [isAwaitingFirstMessage, setIsAwaitingFirstMessage] = useState(true);
    const [isAwaitingLastMessage, setIsAwaitingLastMessage] = useState(true);
    const [count, setCount] = useState(0);
    const [textResetCount, setTextResetCount] = useState(0);
    const [error, setError] = useState(null);
    const [replacedValue, setReplacedValue] = useState(null);
    const [activitySession, setActivitySession] = useState(null);
    const [scoredReply, setScoredReply] = useState(null);
    const [answer, setAnswer] = useState("");
    const [giveUp, setGiveUp] = useState(false);
    const [forceCorrect, setForceCorrect] = useState(false);
    const [complete, setComplete] = useState(false);
    const [grammarCorrections, setGrammarCorrections] = useState([]);
    const [promptContext, setPromptContext] = useState(DEFAULT_PROMPT_CONTEXT);
    const [prompt, setPrompt] = useState(null);
    const [noSpeechFound, setNoSpeechFound] = useState(false);
    const [showExerciseStopDialogue, setShowExerciseStopDialogue] = useState(false);
    const [progress, setProgress] = useState({ steps_completed: 0, steps_total: 0 });
    const [answerRevealed, setAnswerRevealed] = useState(false);
    const [replyActions, setReplyActions] = useState([]);
    const [replyCommands, setReplyCommands] = useState([]);
    const [replyActionType, setReplyActionType] = useState(null);

    const [inFocus, setInFocus] = useState(null);
    const [chatKey, setChatKey] = useState([]);
    const [textMode, setTextMode] = useState(false);

    const [messages, setMessages] = useState([]);
    const [lastMessage, setLastMessage] = useState(null);
    const [taskResult] = useTask(lastMessage?.task_id);

    const nextButtonView = useInView();
    const giveUpRef = useRef(null);
    const endRef = useRef(null);

    const { textEditorView } = useWalkthrough();
    const {
        loadAudio,
        pause: pauseAudio,
        queueAudioToPlay: playAudio,
        stop: stopAudio,
        playAudioChunk,
        endAudioStream,
        playBase64,
    } = useAudio();

    let query = useQuery();

    useEffect(() => {
        // The query parameters aren't relevant anymore.
        if (count > 0 && Array.from(query.keys()).length > 0) {
            history.push(`/spaces/${currentSpace.id}/chat`);
            return;
        }

        setReplacedValue("");
        setPrompt(null);
        setPromptContext({
            missedWordIds: [],
            grammarCorrectedWordIds: [],
            unknownWordIds: [],
            ignoredGrammarCorrections: [],
        });
        setComplete(false);
        stopAudio();
        setIsAwaitingFirstMessage(true);
        setIsAwaitingLastMessage(true);

        prevReplyStreamDataLength.current = 0;
        prevPromptStreamDataLength.current = 0;
        prevCommandStreamDataLength.current = 0;

        let url = `/api/spaces/${currentSpace.id}/prompts/next?`;

        const params = [];

        params.push(`auto_advance_to_next_session=${count === 0 ? "true" : "false"}`);
        if (query.get("activityId")) {
            params.push(`activity_id=${query.get("activityId")}`);
        }

        if (query.get("activitySessionId")) {
            params.push(`activity_session_id=${query.get("activitySessionId")}`);
        }

        let newActivityType = query.get("activityType");
        if (newActivityType) {
            params.push(`activity_type=${newActivityType}`);
        }

        let newActivityTag = query.get("activityTag");
        if (newActivityTag) {
            params.push(`activity_tag=${newActivityTag}`);
        }

        if (query.get("promptDataId")) {
            params.push(`prompt_data_id=${query.get("promptDataId")}`);
        }

        if (query.get("clear") === "true") {
            params.push(`clear_prompts=true`);
        }

        params.push(`supports_audio_streaming=${isMobile.iOS() ? false : true}`);

        url += params.join("&");

        streamPromptAPI("GET", url, null, handleAPIError);
    }, [currentSpace.id, count, query, location.key]);

    function advance() {
        setCount(count + 1);
    }

    useEffect(() => {
        if (promptAPI.error && promptAPI.error.status === 404) {
            history.push(`/spaces/${currentSpace.id}/home`);
        }
    }, [promptAPI.error]);

    useEffect(() => {
        if (prompt) {
            setTextMode(["any", "writing"].includes(prompt.activity.input_mode) || prompt.prompt_type === "review");
            setMessages(prompt.messages);
            setActivitySession(prompt.activity_session);
            setGiveUp(false);
            setAnswer("");
            setAnswerRevealed(false);
            setGrammarCorrections([]);
            setPromptContext({
                missedWordIds: [],
                grammarCorrectedWordIds: [],
                unknownWordIds: [],
                ignoredGrammarCorrections: [],
            });
            setProgress({
                steps_completed: prompt.activity_session.steps_completed,
                steps_total: prompt.activity_session.steps_total,
            });
            setReplyActions(prompt.reply_actions);
            setReplyActionType(prompt.reply_action_type);
            setReplyCommands(prompt.reply_commands);
        }
    }, [prompt]);

    useEffect(() => {
        if (messages?.length > 0) {
            setLastMessage(messages[messages.length - 1]);
            setComplete(messages.filter((message) => message.last_message).length > 0);
        }
        scrollToEnd();
    }, [messages]);

    const submit = (text, skip_corrections = false) => {
        if (answer || text) {
            if (complete) {
                advanceToNextExercise();
            } else {
                sendReply(text, null, skip_corrections);
            }
        }
    };

    const advanceToNextExercise = () => {
        console.log("Prompt: advanceToNextExercise");
        pauseAudio();
        advance();

        document.getElementById("content").classList.remove("chat");
    };

    useEffect(() => {
        if (nextButtonView.entry) {
            document.getElementById("next-button")?.focus();
        }
    }, [nextButtonView.entry]);

    useEffect(() => {
        if (textEditorView.entry) {
            setTimeout(() => document.getElementById("editable")?.focus(), 0);
        }
    }, [textEditorView.entry]);

    const doForceCorrect = (e) => {
        setForceCorrect(true);
    };

    useEffect(() => {
        if (forceCorrect) {
            sendReply(answer);
        }
    }, [forceCorrect]);

    const onSpeechSubmit = (base64Audio) => {
        sendReply(null, base64Audio, true);
    };

    const sendReply = (text, audio, skip_corrections = false) => {
        stopAudio();
        setDisabled(true);
        // this enables auto play
        loadAudio();
        setIsAwaitingFirstMessage(true);
        setIsAwaitingLastMessage(true);

        if (prompt.prompt_type !== "flashcard") {
            setTextResetCount(textResetCount + 1);
        }

        let newMessages = [...messages];
        newMessages.push({
            id: 0,
            message: text,
            transcribing: audio != null,
            from_user: true,
        });
        setMessages(newMessages);

        let payload = {
            message: text,
            message_audio: audio,
            give_up: giveUp,
            force_correct: forceCorrect,
            unknown_word_ids: promptContext.unknownWordIds,
            grammar_corrections_by_word: promptContext.grammarCorrectedWordIds,
            ignored_grammar_corrections: promptContext.ignoredGrammarCorrections,
            skip_corrections: skip_corrections === true ? true : false,
            supports_audio_streaming: isMobile.iOS() ? false : true,
        };

        let url = `/api/prompts/${prompt.id}/reply`;

        streamReplyAPI("POST", url, payload, handleAPIError);
        updateChatKeyToScroll();
    };

    function sendCommand(command, commandLabel, actionId, messageId) {
        if (command === "go:activities") {
            history.push(`/activities`);
            return;
        }

        if (command === "stop") {
            setShowExerciseStopDialogue(true);
            return;
        }

        if (command === "show") {
            doGiveUp();
            return;
        }

        console.log("Sending command", command, commandLabel);
        let payload = {
            message: command,
            store_score: true,
            give_up: false,
            force_correct: false,
            unknown_word_ids: promptContext.unknownWordIds,
            grammar_corrections_by_word: promptContext.grammarCorrectedWordIds,
            ignored_grammar_corrections: promptContext.ignoredGrammarCorrections,
            supports_audio_streaming: isMobile.iOS() ? false : true,
        };

        let url = `/api/prompts/${prompt.id}/command/${command}`;

        if (messageId) url += `?prompt_message_id=${messageId}`;

        streamSendCommandAPI("POST", url, payload, handleAPIError);
        setIsAwaitingFirstMessage(true);
        setIsAwaitingLastMessage(true);
        updateChatKeyToScroll();

        // if we're regenerating, remove all messages that were generated by the system
        let newMessages = [...messages];
        if (["regenerate", "ignore_correction"].includes(command)) {
            // loop through messages in reverse
            for (let i = newMessages.length - 1; i >= 0; i--) {
                if (newMessages[i].from_user) {
                    break;
                } else {
                    // remove message
                    newMessages.splice(i, 1);
                }
            }
            setMessages(newMessages);
        } else if (!messageId) {
            newMessages.push({
                id: newMessages.length,
                message: commandLabel,
                from_user: true,
            });
            setMessages(newMessages);
        }
    }

    const doGiveUp = (e) => {
        setGiveUp(true);
        setAnswerRevealed(true);
        setTimeout(() => {
            console.log("Scrolling to exercise");
            giveUpRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 0);
    };

    useEffect(() => {
        // Process only the new data
        if (promptAPI.streamedData.length > prevPromptStreamDataLength.current) {
            const newData = promptAPI.streamedData.slice(prevPromptStreamDataLength.current);
            processEventMessages(newData);
            prevPromptStreamDataLength.current = promptAPI.streamedData.length;
        }
    }, [promptAPI.streamedData]);

    useEffect(() => {
        // Process only the new data
        if (replyAPI.streamedData.length > prevReplyStreamDataLength.current) {
            const newData = replyAPI.streamedData.slice(prevReplyStreamDataLength.current);
            processEventMessages(newData, true);
            prevReplyStreamDataLength.current = replyAPI.streamedData.length;
        }
    }, [replyAPI.streamedData]);

    useEffect(() => {
        // Process only the new data
        if (sendCommandAPI.streamedData.length > prevCommandStreamDataLength.current) {
            const newData = sendCommandAPI.streamedData.slice(prevCommandStreamDataLength.current);
            processEventMessages(newData, true);
            prevCommandStreamDataLength.current = sendCommandAPI.streamedData.length;
        }
    }, [sendCommandAPI.streamedData]);

    function processEventMessages(newStreamMessages, discardUserMessage = false) {
        for (const event of newStreamMessages) {
            console.log("Processing message", event);
            if (event.message_type === "prompt") {
                setPrompt(event.prompt);
            } else if (event.message_type === "prompt_message") {
                const newMessage = event.prompt_message;
                const newMessageIds = messages.map((message) => message.id);

                setMessages((prevMessages) => {
                    let filteredMessages = discardUserMessage
                        ? prevMessages.filter((message) => message.id !== 0)
                        : prevMessages;
                    filteredMessages = filteredMessages.filter((message) => newMessageIds.includes(message.id));

                    return [...filteredMessages, newMessage];
                });

                if (newMessage.score !== null) {
                    if (!newMessage.score.correct) {
                        setGrammarCorrections(newMessage.score.grammar_corrections);
                        if (!isMobile.any()) {
                            document.getElementById("editable")?.focus();
                        }
                    } else if (prompt?.prompt_type !== "flashcard") {
                        setTextResetCount(textResetCount + 1);
                    }
                } else if (prompt?.prompt_type !== "flashcard") {
                    setPromptContext({ ...promptContext, ignoredGrammarCorrections: [] });
                    setGrammarCorrections([]);
                    if (!isMobile.any()) {
                        document.getElementById("editable")?.focus();
                    }
                }

                if (newMessage.from_user === false) {
                    setIsAwaitingFirstMessage(false);
                }
            } else if (event.message_type === "prompt_message_message") {
                setMessages((previousMessages) => {
                    return previousMessages.map((message) => {
                        if (message.id === event.prompt_message_id) {
                            return {
                                ...message,
                                message: message.message + event.message,
                            };
                        }
                        return message;
                    });
                });
            } else if (event.message_type === "end_chat") {
                setMessages((previousMessages) => {
                    return previousMessages.map((message) => {
                        if (message.id === event.prompt_message_id) {
                            return {
                                ...message,
                                last_message: true,
                            };
                        }
                        return message;
                    });
                });
            } else if (event.message_type === "prompt_message_update") {
                const fieldsToUpdate = {};
                if (event.message) fieldsToUpdate.message = event.message;
                if (event.message_phrase) fieldsToUpdate.message_phrase = event.message_phrase;
                if (event.flashcard) fieldsToUpdate.flashcard = event.flashcard;
                if (event.skill_component_review) fieldsToUpdate.skill_component_review = event.skill_component_review;
                if (event.last_message) fieldsToUpdate.last_message = event.last_message;

                setMessages((previousMessages) => {
                    return previousMessages.map((message) => {
                        if (message.id === event.prompt_message_id) {
                            return {
                                ...message,
                                ...fieldsToUpdate,
                            };
                        }
                        return message;
                    });
                });
            } else if (event.message_type === "discard_prompt_messages") {
                setMessages((previousMessages) => {
                    return previousMessages.filter((message) => !event.prompt_message_ids.includes(message.id));
                });
            } else if (event.message_type === "advance_to_next_chat") {
                advance();
                return;
            } else if (event.message_type === "audio_file") {
                playBase64(event.audio_file);
            } else if (event.message_type === "audio_chunk") {
                playAudioChunk(event.audio_chunk);
            } else if (event.message_type === "audio_end") {
                endAudioStream();
            } else if (event.message_type === "play_audio_phrase") {
                let url = `/api/phrases/${event.phrase_id}/audio?`;

                const params = [];
                if (event.blanks) {
                    params.push(`blanks=${event.blanks.join(",")}`);
                }

                if (event.word_ids) {
                    params.push(`word_ids=${event.word_ids.join(",")}`);
                }

                if (event.voice_id) {
                    params.push(`voice_id=${event.voice_id}`);
                }

                if (params.length > 0) {
                    url += params.join("&");
                }

                playAudio(url);
            } else if (event.message_type === "reply_actions") {
                setReplyActions(event.reply_actions);
                setReplyActionType(event.reply_action_type);
                setReplyCommands(event.reply_commands);
                if (event.advance === true) {
                    advance();
                }
            } else if (event.message_type === "activity_session_progress") {
                setProgress({ ...progress, steps_completed: event.steps_completed });
            } else if (event.message_type === "text_end") {
                setDisabled(false);
            } else if (event.message_type === "end_turn") {
                setIsAwaitingLastMessage(false);
            }
        }

        updateChatKeyToScroll();
    }

    useEffect(() => {
        if (replyAPI.loading === false) {
            prevReplyStreamDataLength.current = 0;
            prevCommandStreamDataLength.current = 0;
            document.getElementById("editable")?.focus();
        }
    }, [replyAPI.loading]);

    useEffect(() => {
        if (sendCommandAPI.loading === false) {
            prevReplyStreamDataLength.current = 0;
            prevCommandStreamDataLength.current = 0;
        }
    }, [sendCommandAPI.loading]);

    useEffect(() => {
        if (!taskResult) {
            return;
        }

        // check to see if it is an array
        if (taskResult.result && Array.isArray(taskResult.result)) {
            // we've got some replies from the task result
            // updateReplies(taskResult.result);
        } else if (taskResult.task.status !== "FAILED") {
            // the task result returned nothing, which means we should advance to the next prompt
            advance();
        } else {
            setError({
                message: strings.activity_creation_problem,
            });
        }
    }, [taskResult]);

    function handleAPIError(error) {
        console.log("Got API Error", error);
        setIsAwaitingFirstMessage(false);
        setIsAwaitingLastMessage(false);

        if ("no_speech_found" === error.errorDetails?.code) {
            setNoSpeechFound(true);
            setDisabled(false);
            replyAPI.setLoading(false);
        }

        let message = error.message;

        if (strings[error.message]) {
            message = strings[error.message];
        }

        if (!prompt) {
            setError(error);
        } else {
            let newMessages = [...messages];
            newMessages.push({
                id: newMessages.length,
                message: message,
                from_user: false,
                tag: "error",
            });
            setMessages(newMessages);

            scrollToEnd();
        }
    }

    function updateChatKeyToScroll() {
        let newChatKey = [replyAPI.response, promptAPI.loading, giveUp];
        setChatKey(newChatKey);
    }

    function scrollToEnd() {
        setTimeout(scrollToEndInternal, 0);
    }

    function scrollToEndInternal() {
        endRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    function onFocus(event, inFocus) {
        console.log("In focus", inFocus);
        setInFocus(inFocus);

        if (giveUp === false && inFocus) {
            setTimeout(
                scrollToEnd,
                // Android requires more time for the keyboard to pop up
                isMobile.Android() ? 750 : 200
            );
        }
    }

    useEffect(() => {
        setDisabled(promptAPI.loading === true || replyAPI.loading === true || sendCommandAPI.loading === true);
    }, [promptAPI.loading, replyAPI.loading, sendCommandAPI.loading]);

    function onApplyCorrection(correction, replacement) {
        callAcceptGrammrCorrectionAPI(
            "POST",
            `/api/grammar/accept`,
            {
                correction_ids: correction.source_correction_ids,
            },
            null,
            () => null
        );
    }

    if (error) {
        // At this point, no prompt is loaded, so we need to render the chat manually
        return (
            <Chat autoScroll={true} messages={error}>
                <ChatRowLeft>
                    <ChatMessageLeft>{error.message}</ChatMessageLeft>
                </ChatRowLeft>

                {error.retrying && (
                    <ChatRowLeft>
                        <ChatMessageLeft>
                            <ChatWaitingIndiciator />
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}
                <ChatInput className={"solid-bottom-bar"}>
                    <Container />
                    <div
                        className="command-options"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1rem",
                            flexWrap: "wrap",
                        }}>
                        <Button
                            content={strings.home}
                            icon={<House />}
                            onClick={() => history.push(`/spaces/${currentSpace.id}/home`)}
                            primary
                        />
                    </div>
                    <div
                        style={{
                            marginBottom: "0.5rem",
                        }}
                    />
                </ChatInput>
            </Chat>
        );
    }

    return (
        <Form>
            <fieldset disabled={disabled}>
                <TopBar>
                    {activitySession && (
                        <ActivityProgressBar
                            icon={activitySession.activity.emoji}
                            title={activitySession.activity.name_native}
                            points={progress.steps_completed}
                            total={progress.steps_total}
                            onClose={() => history.push(`/spaces/${currentSpace.id}/home`)}
                        />
                    )}
                </TopBar>

                <Chat autoScroll={true} messages={chatKey}>
                    <PromptChat
                        key={prompt ? prompt.id : "empty-chat"}
                        prompt={prompt}
                        activity={activitySession?.activity}
                        promptContext={promptContext}
                        setPromptContext={setPromptContext}
                        messages={messages}
                        lastMessage={lastMessage}
                        statistics={promptAPI?.response?.activity?.statistics}
                        endRef={endRef}
                        isAwaitingFirstMessage={isAwaitingFirstMessage}
                        doGiveUp={doGiveUp}
                        giveUp={giveUp}
                        giveUpRef={giveUpRef}
                        forceCorrect={forceCorrect}
                        doForceCorrect={doForceCorrect}
                        completed={scoredReply?.score?.correct}
                        sendCommand={sendCommand}
                        sendCommandAPI={sendCommandAPI}
                        inFocus={inFocus}
                        setAnswerRevealed={setAnswerRevealed}
                        showExerciseStopDialogue={showExerciseStopDialogue}
                        setShowExerciseStopDialogue={setShowExerciseStopDialogue}
                    />

                    <ChatInput className={complete ? "solid-bottom-bar" : "white-bottom-bar"}>
                        {prompt && lastMessage?.tag !== "task" && (
                            <Container
                                style={{
                                    display: prompt.prompt_type === "flashcard" && disabled ? "none" : null,
                                }}>
                                {complete && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            margin: "1rem 0",
                                        }}>
                                        <div ref={nextButtonView.ref} />

                                        <Button
                                            id="next-button"
                                            onClick={advanceToNextExercise}
                                            style={{ minWidth: "20rem", fontSize: "1rem" }}
                                            content={strings.next}
                                            primary></Button>
                                    </div>
                                )}
                                {!complete && (
                                    <Form>
                                        <fieldset disabled={disabled}>
                                            {messages.length > 0 && (
                                                <PromptButtonBar
                                                    prompt={prompt}
                                                    sendCommand={sendCommand}
                                                    sendResponse={submit}
                                                    disabled={disabled}
                                                    lastMessage={lastMessage}
                                                    hasGrammarCorrections={
                                                        grammarCorrections !== null && grammarCorrections.length > 0
                                                    }
                                                    onResize={scrollToEnd}
                                                    textMode={textMode}
                                                    setTextMode={setTextMode}
                                                    replyCommands={replyCommands}
                                                />
                                            )}

                                            {messages.length > 0 && !promptAPI.loading && (
                                                <PromptInputBar
                                                    resetCount={textResetCount}
                                                    textMode={textMode}
                                                    textEditorRef={textEditorView.ref}
                                                    activityId={promptAPI?.response?.activity_id}
                                                    submit={submit}
                                                    onSpeechSubmit={onSpeechSubmit}
                                                    sendCommand={sendCommand}
                                                    onChange={setAnswer}
                                                    disabled={disabled}
                                                    isAwaitingLastMessage={isAwaitingLastMessage}
                                                    onFocus={onFocus}
                                                    grammarCorrections={grammarCorrections}
                                                    ignoredGrammarCorrections={promptContext.ignoredGrammarCorrections}
                                                    setIgnoredGrammarCorrections={(ignoredGrammarCorrections) =>
                                                        setPromptContext({
                                                            ...promptContext,
                                                            ignoredGrammarCorrections: ignoredGrammarCorrections,
                                                        })
                                                    }
                                                    autoCapitalize={prompt?.prompt_type !== "review"}
                                                    onApplyCorrection={onApplyCorrection}
                                                    sendCorrections={
                                                        !["review", "translation"].includes(prompt?.prompt_type)
                                                    }
                                                    replacedValue={replacedValue}
                                                    setReplacedValue={setReplacedValue}
                                                    lastMessage={lastMessage}
                                                    language={prompt.language}
                                                    nativeLanguage={
                                                        prompt.native_language
                                                            ? prompt.native_language
                                                            : prompt.language
                                                    }
                                                    revealCorrections={lastMessage?.show_corrections || answerRevealed}
                                                    replyActions={replyActions}
                                                    replyActionType={replyActionType}
                                                />
                                            )}

                                            <div
                                                style={{
                                                    marginBottom: "0.5rem",
                                                }}
                                            />
                                        </fieldset>
                                    </Form>
                                )}
                            </Container>
                        )}
                    </ChatInput>
                </Chat>
            </fieldset>
        </Form>
    );
}

export const PromptChat = memo(
    ({
        prompt,
        activity,
        messages,
        lastMessage,
        isAwaitingFirstMessage,
        promptContext,
        setPromptContext,
        doGiveUp,
        giveUp,
        giveUpRef,
        endRef,
        forceCorrect,
        doForceCorrect,
        completed = true,
        sendCommand,
        sendCommandAPI,
        inFocus,
        setAnswerRevealed,
        showExerciseStopDialogue,
        setShowExerciseStopDialogue,
    }) => {
        /**
         * Using a callback here so that the message doesn't get re-rendered unless
         * the properties we actually care about change.
         */
        const renderMessage = useCallback(
            (message, messageIndex, messages) => {
                return (
                    <>
                        {(message.tag !== "task" ||
                            (message.tag === "task" && message === lastMessage && message.message !== "")) && (
                            <PromptChatMessage
                                key={"message-" + message.id}
                                prompt={prompt}
                                activity={activity}
                                promptContext={promptContext}
                                setPromptContext={setPromptContext}
                                message={message}
                                messageIndex={messageIndex}
                                messages={messages}
                                autoPlayAudio={false}
                                doGiveUp={doGiveUp}
                                giveUp={giveUp}
                                forceCorrect={forceCorrect}
                                doForceCorrect={doForceCorrect}
                                completed={completed}
                                sendCommand={sendCommand}
                                sendCommandAPI={sendCommandAPI}
                                giveUpRef={giveUpRef}
                                setAnswerRevealed={setAnswerRevealed}
                                isFirstBubble={
                                    messageIndex === 0 || messages[messageIndex - 1].from_user !== message.from_user
                                }
                                isLastBubble={
                                    messages.length === messageIndex + 1 ||
                                    messages[messageIndex + 1].from_user !== message.from_user
                                }
                                showExerciseStopDialogue={showExerciseStopDialogue}
                                setShowExerciseStopDialogue={setShowExerciseStopDialogue}
                            />
                        )}
                    </>
                );
            },
            [inFocus, giveUp, messages, promptContext?.unknownWordIds, showExerciseStopDialogue]
        );

        return (
            <>
                {prompt &&
                    messages &&
                    messages.length > 0 &&
                    messages.map((message, idx) => (
                        <React.Fragment key={"message-wrapper-" + idx}>
                            {renderMessage(message, idx, messages)}
                        </React.Fragment>
                    ))}

                {(!prompt || !messages || messages.length === 0 || isAwaitingFirstMessage) && (
                    <ChatRowLeft key={"typing"} className="chat-bubble-last">
                        <ChatMessageLeft>
                            <ChatWaitingIndiciator />
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}
                <div ref={endRef} className={"promptend"} />
            </>
        );
    }
);

const TaskAchievementMessages = ({ prompt, message }) => {
    const [tasksAchieved, setTasksAcheived] = useState([]);
    const [skillsDemonstrated, setSkillsDemonstrated] = useState([]);

    const celebratoryEmojis = ["🤩", "🌟", "🎉", "🔥", "🥳"];
    const celebratoryEmoji = celebratoryEmojis[message.id % celebratoryEmojis.length];
    const [taskResult] = useTask(message.score_task_id);

    function updateTasksCompleted(tasks_completed, skills_demonstrated) {
        const newTasksAchieved = [];
        for (const goal of prompt.activity.activity_tasks) {
            if (tasks_completed && tasks_completed.includes(goal.id)) {
                newTasksAchieved.push(goal);
            }
        }
        setTasksAcheived(newTasksAchieved);
    }

    useEffect(() => {
        if (!prompt.activity?.activity_tasks) {
            return;
        }

        if (!message.score_task_id) {
            updateTasksCompleted(message.tasks_completed, message.skills_demonstrated);
        }
    }, [prompt, message]);

    useEffect(() => {
        if (taskResult?.result) {
            updateTasksCompleted(taskResult.result.tasks_completed, taskResult.result.skills_demonstrated);
        }
    }, [taskResult]);

    if (!tasksAchieved && message.score_task_id && !taskResult) {
        return (
            <div style={{ textAlign: "right", width: "100%", marginBottom: "0.5rem" }}>
                <Loader active inline size="tiny" />
            </div>
        );
    }

    return (
        <>
            {tasksAchieved.map((goal) => (
                <div style={{ textAlign: "right", width: "100%", marginBottom: "0.5rem" }}>
                    {celebratoryEmoji} {goal.description_native}
                </div>
            ))}
            {skillsDemonstrated.map((skill) => (
                <div style={{ textAlign: "right", width: "100%", marginBottom: "0.5rem" }}>
                    ⭐️ {skill.description_native}
                </div>
            ))}
        </>
    );
};

export const PromptChatMessage = memo(
    ({
        prompt,
        activity,
        message,
        messageIndex,
        messages,
        setPlayingAudio,
        giveUp,
        promptContext,
        setPromptContext,
        sendCommand,
        autoPlayAudio,
        doForceCorrect,
        completed = true,
        isFirstBubble,
        isLastBubble,
        setAnswerRevealed,
        showExerciseStopDialogue,
        setShowExerciseStopDialogue,
    }) => {
        const [showNativeHint, setShowNativeHint] = useState(true);

        const { promptView, grammarThumbsDownView, clozeView, hintView } = useWalkthrough();

        const renderHTML = (rawHTML) =>
            React.createElement("div", {
                dangerouslySetInnerHTML: { __html: rawHTML },
            });

        function format(text) {
            text = text.replace(/\n/g, "<br/>");

            return React.createElement("span", {
                dangerouslySetInnerHTML: { __html: text },
            });
        }

        function setUnknownWordIds(unknownWordIds) {
            let newUnknownWordIds = promptContext.unknownWordIds;
            newUnknownWordIds.push(...unknownWordIds);
            setPromptContext({ ...promptContext, unknownWordIds: newUnknownWordIds });
        }

        return (
            <React.Fragment key={message.id + "-chat-row-fragment"}>
                {(message?.message !== "" || message.transcribing) && message.from_user && (
                    <ChatRowRight
                        key={message.id + "-chat-row"}
                        className={
                            (isFirstBubble ? "chat-bubble-first " : "") + (isLastBubble ? "chat-bubble-last" : "")
                        }>
                        <ChatMessageRight>
                            {!message.message_phrase && message.message && format(message.message)}
                            {message.message_phrase && (
                                <Phrase
                                    phrase={message.message_phrase}
                                    alignments={message.message_phrase.alignments}
                                    fromOrTarget="from"
                                    translations={message.message_phrase.translations}
                                    translationLanguage={prompt.native_language}
                                    unknownWordIds={[]}
                                    setUnknownWordIds={() => null}
                                    missedWordIds={[]}
                                    blanks={message.blanks}
                                    markUknownRemotely={true}
                                    allowFullTranslation={true}
                                    promptMessageId={message.id}
                                    enableButtons={false}
                                />
                            )}
                            {message.transcribing && <Loader active inline size="mini" />}

                            {message.corrected_message && (
                                <div className="correction">
                                    <div className="correction-message">{message.corrected_message}</div>
                                    {message.correction_explanation && (
                                        <div className="correction-explanation">{message.correction_explanation}</div>
                                    )}
                                </div>
                            )}
                        </ChatMessageRight>
                        <ChatIconRight>{strings.you}</ChatIconRight>
                    </ChatRowRight>
                )}

                <TaskAchievementMessages prompt={prompt} message={message} />

                {message.tag === "prompt" && <span ref={promptView.ref}></span>}

                {!message.from_user && (
                    <ChatRowLeft
                        key={message.id + "-chat-row"}
                        className={
                            (isFirstBubble ? "chat-bubble-first " : "") +
                            (isLastBubble ? "chat-bubble-last" : "") +
                            (message.tag ? " " + message.tag : "")
                        }>
                        <ChatIconLeft></ChatIconLeft>
                        <ChatMessageLeft id={message.tag}>
                            {message.flashcard && (
                                <Instructions
                                    review={message.skill_component_review}
                                    instructions={toCoolEmojis(
                                        strings["prompt_exercise_type_" + message.flashcard.type]
                                    )}
                                    prompt={prompt}
                                />
                            )}
                            {message.flashcard && (
                                <FlashcardStopPopup
                                    flashcard={message.flashcard}
                                    skillComponentReview={message.skill_component_review}
                                    sendCommand={sendCommand}
                                    isOpen={showExerciseStopDialogue}
                                    setIsOpen={setShowExerciseStopDialogue}
                                />
                            )}
                            {message.tag === "hint" && (
                                <>
                                    <span ref={hintView.ref}></span>
                                    {(!message.message_phrase || showNativeHint) && (
                                        <p>
                                            {strings.flashcard_hint}: {message.cloze_details.hint_native}
                                        </p>
                                    )}
                                    {message.message_phrase && !showNativeHint && (
                                        <p>
                                            {strings.flashcard_hint}: {message.cloze_details.hint_native_hidden}{" "}
                                            <Button size="small" compact onClick={() => setShowNativeHint(true)}>
                                                {strings.formatString(
                                                    strings.flashcard_show_native_hint,
                                                    strings[prompt.language]
                                                )}
                                            </Button>
                                        </p>
                                    )}
                                </>
                            )}

                            {message.message_phrase && message.flashcard === null && (
                                <Phrase
                                    phrase={message.message_phrase}
                                    alignments={message.message_phrase.alignments}
                                    fromOrTarget={"from"}
                                    translations={message.message_phrase.translations}
                                    translationLanguage={prompt.native_language}
                                    blanks={message.blanks}
                                    markUknownRemotely={true}
                                    allowFullTranslation={true}
                                    autoPlayAudio={autoPlayAudio}
                                    setPlayingAudio={setPlayingAudio}
                                    squidgyId={prompt.id}
                                    promptMessageId={message.id}
                                    unknownWordIds={promptContext.unknownWordIds}
                                    setUnknownWordIds={setUnknownWordIds}
                                    inline={true}
                                />
                            )}

                            {message.message &&
                                !message.message_phrase &&
                                !["flashcard", "vocab"].includes(message.tag) &&
                                format(message.message)}

                            {message.attachment_html && renderHTML(message.attachment_html)}

                            {message.flashcard?.type === "translation" && (
                                <Phrase
                                    phrase={message.flashcard.phrase}
                                    fromOrTarget="from"
                                    translationLanguage={prompt.language}
                                    unknownWordIds={promptContext.unknownWordIds}
                                    setUnknownWordIds={setUnknownWordIds}
                                    grammarCorrectedWordIds={promptContext.grammarCorrectedWordIds}
                                    missedWordIds={promptContext.missedWordIds}
                                    squidgyId={prompt.id}
                                    allowFullTranslation={completed}
                                    promptMessageId={message.id}
                                    giveUp={giveUp}
                                    autoPlayAudio={autoPlayAudio}
                                    setPlayingAudio={setPlayingAudio}
                                />
                            )}

                            {["transcribe"].includes(message.flashcard?.type) && (
                                <>
                                    {giveUp && (
                                        <Phrase
                                            phrase={message.flashcard.phrase}
                                            inline={true}
                                            fromOrTarget="from"
                                            translationLanguage={prompt.native_language}
                                            setPlayingAudio={setPlayingAudio}
                                            promptMessageId={message.id}
                                            markUknownRemotely={true}
                                            giveUp={giveUp}
                                            allowFullTranslation={true}
                                        />
                                    )}

                                    <AudioPhrase
                                        phrase={message.flashcard.phrase}
                                        autoPlayAudio={autoPlayAudio}
                                        setPlayingAudio={setPlayingAudio}
                                    />
                                </>
                            )}
                            {["repeat_after_me", "rephrase"].includes(message.flashcard?.type) && (
                                <span ref={clozeView.ref}>
                                    <Phrase
                                        phrase={message.flashcard.phrase}
                                        inline={false}
                                        fromOrTarget="from"
                                        translationLanguage={prompt.native_language}
                                        allowFullTranslation={true}
                                        promptMessageId={message.id}
                                        giveUp={giveUp}
                                        markUknownRemotely={true}
                                    />
                                </span>
                            )}
                            {["cloze", "multiple_choice", "question", "question_mc"].includes(
                                message.flashcard?.type
                            ) && (
                                <span ref={clozeView.ref}>
                                    <Phrase
                                        phrase={message.flashcard.phrase}
                                        inline={false}
                                        fromOrTarget="from"
                                        translationLanguage={prompt.native_language}
                                        unknownWordIds={promptContext.unknownWordIds}
                                        setUnknownWordIds={setUnknownWordIds}
                                        grammarCorrectedWordIds={promptContext.grammarCorrectedWordIds}
                                        missedWordIds={promptContext.missedWordIds}
                                        blanks={message.flashcard.cloze_answer_word_ids}
                                        autoPlayAudio={autoPlayAudio && !giveUp}
                                        setPlayingAudio={setPlayingAudio}
                                        squidgyId={prompt.id}
                                        allowFullTranslation={completed}
                                        promptMessageId={message.id}
                                        giveUp={giveUp}
                                        markUknownRemotely={true}
                                        setRevealed={setAnswerRevealed}
                                    />
                                </span>
                            )}
                        </ChatMessageLeft>
                    </ChatRowLeft>
                )}

                {message.ref && <div ref={message.ref} />}
            </React.Fragment>
        );
    }
);

function Instructions({ instructions, review }) {
    return (
        <SkillComponentDetailsPopup
            skillComponentId={review?.skill_component_id}
            trigger={
                <div className="ExerciseInstruction">
                    {review !== null ? (
                        <span style={{ whiteSpace: "nowrap" }}>
                            <Label basic size="small">
                                <span>
                                    {review.forgetting_index === null && <>&nbsp;&#x1F331;</>}
                                    {review.forgetting_index >= 0.5 && <>&#x1F4AA;</>}
                                    {review.forgetting_index >= 0.125 && review.forgetting_index < 0.5 && (
                                        <>&#x1F4AA;&#x1F4AA;</>
                                    )}
                                    {review.forgetting_index >= 0.0625 && review.forgetting_index < 0.125 && (
                                        <>&#x1F4AA;&#x1F4AA;&#x1F4AA;</>
                                    )}
                                    {review.forgetting_index !== null && review.forgetting_index < 0.0625 && (
                                        <>&#x1F4AA;&#x1F4AA;&#x1F4AA;&#x1F4AA;</>
                                    )}
                                </span>
                                &nbsp;
                                {instructions}
                            </Label>
                        </span>
                    ) : (
                        <span>{instructions}</span>
                    )}
                </div>
            }
        />
    );
}
