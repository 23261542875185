import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "../hooks/useAuth.js";
import { strings } from "../utils/i18n.utils.js";

const languages = {
    "en-US": "en",
    "en-GB": "en",
};

function browserLanguage() {
    const language = navigator.language;

    return languages[language] || language;
}

export const LanguageProviderContext = React.createContext({
    language: null,
    nativeLanguage: browserLanguage() || "en",
});

const LanguageProvider = ({ children }) => {
    const { language, nativeLanguage } = useParams();
    const { user, currentSpace } = useAuth();
    const [_language, _setLanguage] = useState();
    const [_nativeLanguage, _setNativeLanguage] = useState(
        nativeLanguage || user?.current_native_language || browserLanguage() || "en"
    );

    useEffect(() => {
        // Got a new URL param, use that
        console.log("Got new language", language);
        if (language) {
            _setLanguage(language);
        }
    }, [currentSpace?.language]);

    useEffect(() => {
        console.log("Got new native language", nativeLanguage);
        // Got a new URL param, use that
        if (nativeLanguage) {
            _setNativeLanguage(nativeLanguage);
            strings.setLanguage(nativeLanguage);
        }
    }, [currentSpace?.native_language]);

    const contextValue = {
        language: _language || user?.current_language,
        nativeLanguage: _nativeLanguage || user?.current_native_language || browserLanguage() || "en",
    };

    return <LanguageProviderContext.Provider value={contextValue}>{children}</LanguageProviderContext.Provider>;
};
export default LanguageProvider;
