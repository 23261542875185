import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import { strings } from "../utils/i18n.utils";

export const AuthProviderContext = React.createContext({
    user: null,
    userLoading: true,
    userError: null,
    setUser: () => {},
    setStatistics: () => {},
    setLanguageSettings: () => {},
    cookie: null,
    setCookie: () => {},
    currentSpace: null,
    setCurrentSpace: () => {},
    switchSpace: () => {},
});

export default function AuthProvider({ loginPage, children }) {
    const [user, setUser] = useState(null);
    const [currentSpace, setCurrentSpace] = useState(null);
    const [cookie, setCookie] = useState(null);
    const [userLoadCount, setUserLoadCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    const [{ response, error }, callAPI] = useAPI({
        doNotRedirect: true,
        showError: false,
    });

    const setLanguageSettings = (languageSettings) => {
        setCurrentSpace({ ...currentSpace, user_language_settings: languageSettings });
    };

    const setStatistics = (statistics) => {
        setCurrentSpace({ ...currentSpace, statistics: statistics });
    };

    useEffect(() => {
        if (history.location.pathname.startsWith("/login")) {
            setLoading(false);
            return;
        }

        switchSpace();
    }, [userLoadCount]);

    function switchSpace(spaceId) {
        let url = `/api/user`;
        if (spaceId) {
            url += `?current_space_id=${spaceId}`;
        }

        callAPI("GET", url);
        setLoading(true);
    }

    useEffect(() => {
        if (response) {
            setUser(response);
            setCurrentSpace(response.current_space);
            setLoading(false);
        } else {
            setUser(null);
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    useEffect(() => {
        if (currentSpace) {
            strings.setLanguage(currentSpace.native_language);
        }
    }, [currentSpace]);

    useEffect(() => {
        if (user && window.location.host !== "localhost:3000") {
            console.log("Logging in with LogRocket", user);
            if (window.location.host !== "localhost:3000") {
                console.log("Initiailizing LogRocket", window.location.host);
                LogRocket.init("n4eqwe/jetway");
                LogRocket.identify(user.id, {
                    email: user.email,
                });
            }
        }
    }, [user]);

    const contextValue = {
        user,
        userLoading: loading,
        userError: error,
        setUser: setUser,
        setStatistics,
        setLanguageSettings,
        cookie,
        setCookie,
        currentSpace,
        setCurrentSpace,
        switchSpace,
    };

    if (loading)
        return (
            <AuthProviderContext.Provider value={contextValue}>
                {true && (
                    <Container
                        style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "100%",
                        }}>
                        <div
                            style={{
                                display: "flex",
                                alignContent: "",
                                justifyContent: "center",
                                flexDirection: "row",
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    height: "100%",
                                }}>
                                <div style={{ flexGrow: 4 }} />
                                <img src={`/logo.svg`} style={{ width: "5em" }} />
                            </div>
                            <div style={{ margin: "0.5rem" }} />
                            <Segment style={{ width: "unset" }}>
                                {loading && <ZataLoader />}
                                {!loading && error.message}
                            </Segment>
                        </div>
                    </Container>
                )}
            </AuthProviderContext.Provider>
        );

    if (!user) {
        return loginPage;
    }

    return <AuthProviderContext.Provider value={contextValue}>{children}</AuthProviderContext.Provider>;
}
