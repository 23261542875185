import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SkillComponentDetails from "../components/SkillComponentDetails";

const SkillComponentScreen = () => {
    const { skillComponentId } = useParams();

    return <SkillComponentDetails skillComponentId={skillComponentId} />;
};

export default SkillComponentScreen;
