export function getBaseUrl() {
    let baseUrl = process.env.REACT_APP_WEB_URL;

    // if the host name doesn't include jetway or localhost, use the hostname instead
    if (!window.location.host.includes("localhost")) {
        baseUrl = window.location.protocol + "//" + window.location.host;
    }

    return baseUrl;
}

export function currentLocation() {
    return encodeURIComponent(window.location.pathname + window.location.search);
}

export function addRedirectTo(url) {
    return url + `?redirectTo=${window.location.pathname}${encodeURIComponent(window.location.search)}`;
}
